<template>
  <div class="lg:pl-72">
    <div
      v-if="showNavbar"
      class="sticky top-0 z-30 flex shrink-0 items-center gap-x-4 bg-yellow-50 px-4 sm:gap-x-6 sm:px-6 lg:px-8 py-2"
    >
      <div class="flex items-center flex-1 gap-x-4 self-stretch">
        <div class="flex-shrink-0">
          <ExclamationTriangleIcon
            class="h-5 w-5 text-yellow-400"
            aria-hidden="true"
          />
        </div>
        <p class="text-xs text-yellow-700">
          <span class="font-semibold mr-1">Incomplete Data!</span
          ><span class="mr-1">{{ message }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ExclamationTriangleIcon } from "@heroicons/vue/20/solid";

const adAccountStore = useAdAccountStore();
const clientStore = useClientStore();

const accountsOfClient = computed(() =>
  adAccountStore.notDeletedAdAccounts.filter(
    (acc) => acc.clientId === clientStore.activeClientId,
  ),
);
const accountsWithoutLifetimeData = computed(() =>
  accountsOfClient.value.filter((acc) => !acc.hasFetchedLifetimeData),
);
const showNavbar = computed(() => accountsWithoutLifetimeData.value.length > 0);
const message = computed(() => {
  const count = accountsWithoutLifetimeData.value.length;
  if (count === 1) {
    return "There is 1 account in this client currently loading historical data. Reports might be incomplete!";
  }
  return `There are ${count} accounts in this client currently loading historical data. Reports might be incomplete!`;
});
</script>
